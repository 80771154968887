import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../../components/layout'
import Img from 'gatsby-image'
import { graphql, Link } from 'gatsby'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
}

const ServiceDesignLevicoTerme = props => (
  <Layout>
    <Helmet>
      <title>Service Design - levico terme</title>
      <meta name="Service Design - levico terme" content="Service Design" />
    </Helmet>

    <div id="main" className="alt">
      <section id="work-banner">
        <div>
          <span className="image main">
            <Img fluid={props.data.levicocover.childImageSharp.fluid} alt="" />
          </span>
        </div>
      </section>

      <div className="project-wrapper">
        <div className="project-content">
          <header>
            <span className="tag">SERVICE DESIGN</span>
            <span className="tag">UX STRATEGY</span>
            <span className="tag">BUSINESS DESIGN</span>
            <span className="tag">USER EXPERIENCE</span>
            <h1>
              levico terme and <br />
              sports medical centre.
            </h1>
            <h3>
              The redesigned thermal centre is a Sports and Clinical Research
              centre for professional athletes and sports teams. Gamification
              techniques are used to provide customised holistic sports services
              around performance and rehabilitation through a state of the art
              Medical Terme.
            </h3>
          </header>
          <section className="challenge-section">
            <span className="project-step-number">01</span>
            <h2>challenge</h2>
            <div className="challenge">
              <div className="challenge-desc">
                <p>
                  We teamed up with Terme di Levico (a thermal centre in the
                  city of Trentino, Italy) to redesign their thermal ecosystem
                  by developing a strategic service concept. This was to be done
                  by defining new usage scenarios anticipating new targets with
                  dedicated services recognizable for the experiential and
                  participatory level experience design.
                </p>
              </div>
              <div className="challenge-credits">
                <div className="topbar" />
                <span className="credits-text">
                  <strong>YEAR:</strong> 2016
                </span>
                <span className="credits-text">
                  <strong>DURATION:</strong> 3 weeks
                </span>
                <span className="credits-text">
                  <strong>PROJECT TYPE:</strong> Service, Strategic & System
                  design in the Hospitality context.
                </span>
                <span className="credits-text">
                  <strong>TEAM MEMBERS:</strong> Antonis Trantas, Bruna Kochi &
                  Erica Invernizzi
                </span>
                <span className="credits-text">
                  <strong>MY ROLE:</strong> Desk research, Qualitative
                  interviews, User behaviour observations, Ideation,
                  Conceptualisation, Journey mapping, Lean UX/UI, Brand
                  direction, Strategy, Business model generation &
                  Storyboarding.
                </span>
              </div>
            </div>
            <div id="project-images-wrapper">
              <Slider className="image-scroll" {...settings}>
                <Img
                  fluid={props.data.levicochallenge1.childImageSharp.fluid}
                />
                <Img
                  fluid={props.data.levicochallenge2.childImageSharp.fluid}
                />
                <Img
                  fluid={props.data.levicochallenge3.childImageSharp.fluid}
                />
                <Img
                  fluid={props.data.levicochallenge4.childImageSharp.fluid}
                />
                <Img
                  fluid={props.data.levicochallenge5.childImageSharp.fluid}
                />
                <Img
                  fluid={props.data.levicochallenge6.childImageSharp.fluid}
                />
              </Slider>
            </div>
            <div className="full-text">
              Over the course of the project, we answered key questions.
              <ol>
                <li>How can we enhance the natural dimension of territory?</li>
                <li>
                  How can we leverage the therapeutic qualities of the waters to
                  provide value added services?
                </li>
                <li>
                  How the current offered treatments can be enhanced to build a
                  holistic, seamless & personalised service ecosystem
                </li>
              </ol>
              <p>
                The challenge was to develop new scenarios of development for
                the Trentino’s system.
              </p>
            </div>
          </section>
          <section className="process-section">
            <span className="project-step-number">02</span>
            <h2>process</h2>

            <div className="process">
              <div className="process-left">
                <p>
                  I had the opportunity to work with an interdisciplinary team
                  of Service Designer, Interior Designer, Interaction designer
                  and Hospitality & Management graduate, all coming from
                  different cultural backgrounds and understandings.
                </p>
              </div>
              <div className="process-right">
                <p>
                  We initiated the process by populating an initial mind-mapping
                  to understand the bigger picture and its various elements,
                  curating a research plan and a project timeline laying out
                  goals and deliverables at each stage of the project.
                </p>
              </div>
            </div>
            <div className="process-image">
              <Img fluid={props.data.levico_process.childImageSharp.fluid} />
            </div>
            <p className="full-text">
              We began our initial research by carrying out desk research to
              understand the strengths & weaknesses of the region and the terme.
              After gathering secondary data, we visited the trentino region and
              the Levico Terme facility to understand the issues from the
              perspective of the key players in the ecosystem. We conducted
              informal and formal contextual interviews with users and other
              important stakeholders to develop empathy and understand their
              needs.
            </p>
            <div id="project-images-wrapper">
              <Slider className="image-scroll" {...settings}>
                <Img fluid={props.data.levicoprocess7.childImageSharp.fluid} />
                <Img fluid={props.data.levicoprocess8.childImageSharp.fluid} />
                <Img fluid={props.data.levicoprocess9.childImageSharp.fluid} />
                <Img fluid={props.data.levicoprocess10.childImageSharp.fluid} />
              </Slider>
            </div>
            <p className="full-text">
              From our research we had a key insight; the Trento/Levico region
              is a popular destination for Football summer camps. Over the past
              2 years popular European Football teams from Italy (Juventus FC &
              Inter Milan) & Abroad (Bayern Munich FC) have visited the region
              for pre-season training due to high altitude & good surrounding
              environment. This opportunity resulted in putting a focus on
              Sports and transforming the place into a Terme & a Sports Medical
              Centre.
            </p>
            <div className="two-sidebyside">
              <div className="img left">
                <Img fluid={props.data.levicoprocess11.childImageSharp.fluid} />
              </div>
              <div className="img right">
                <Img fluid={props.data.levicoprocess12.childImageSharp.fluid} />
              </div>
            </div>
            <p className="full-text">
              The insights were used to brainstorm on ideas, build personas and
              map out new user journey/experience maps. The selected ideas were
              presented to the various stakeholders from the Levico Terme in a
              co-designing workshop session. The feedback gained from that
              session was used to develop the ideas further into a concrete
              system focused service solution.
            </p>
            <div className="two-sidebyside">
              <div className="img left">
                <Img fluid={props.data.levicoprocess13.childImageSharp.fluid} />
              </div>
              <div className="img right">
                <Img fluid={props.data.levicoprocess14.childImageSharp.fluid} />
              </div>
            </div>
            <p className="fuul-text">
              The feedback gained from that session was used to develop the
              ideas further into a concrete system focused service solution.
            </p>
            <Slider className="image-scroll" {...settings}>
              <Img fluid={props.data.levicoprocess15.childImageSharp.fluid} />
              <Img fluid={props.data.levicoprocess16.childImageSharp.fluid} />
              <Img fluid={props.data.levicoprocess17.childImageSharp.fluid} />
              <Img fluid={props.data.levicoprocess18.childImageSharp.fluid} />
              <Img fluid={props.data.levicoprocess19.childImageSharp.fluid} />
              <Img fluid={props.data.levicoprocess20.childImageSharp.fluid} />
              <Img fluid={props.data.levicoprocess21.childImageSharp.fluid} />
              <Img fluid={props.data.levicoprocess22.childImageSharp.fluid} />
            </Slider>
            <p className="full-text">
              Finally, the concept was elaborated to create a service
              experience. This experience was presented in a tangible manner
              with the help of storyboard sketches.
            </p>
            <Slider className="image-scroll" {...settings}>
              <Img fluid={props.data.levicoprocess23.childImageSharp.fluid} />
              <Img fluid={props.data.levicoprocess24.childImageSharp.fluid} />
              <Img fluid={props.data.levicoprocess25.childImageSharp.fluid} />
              <Img fluid={props.data.levicoprocess26.childImageSharp.fluid} />
              <Img fluid={props.data.levicoprocess27.childImageSharp.fluid} />
              <Img fluid={props.data.levicoprocess28.childImageSharp.fluid} />
              <Img fluid={props.data.levicoprocess29.childImageSharp.fluid} />
              <Img fluid={props.data.levicoprocess30.childImageSharp.fluid} />
              <Img fluid={props.data.levicoprocess31.childImageSharp.fluid} />
              <Img fluid={props.data.levicoprocess32.childImageSharp.fluid} />
              <Img fluid={props.data.levicoprocess33.childImageSharp.fluid} />
              <Img fluid={props.data.levicoprocess34.childImageSharp.fluid} />
              <Img fluid={props.data.levicoprocess35.childImageSharp.fluid} />
              <Img fluid={props.data.levicoprocess36.childImageSharp.fluid} />
            </Slider>
          </section>
          <section className="outcome-section">
            <div className="outcome-text-wrapper">
              <span className="project-step-number">03</span>
              <h2>outcome</h2>
              <h3>
                The redesign of the Levico Terme resulted in an innovate Sports
                & Clinical Research Centre. We leveraged the supreme location to
                put our focus on Sports performance & recovery services for
                athletes and sports teams preparing or recovering from a
                competition.
              </h3>
              <div className="outcome">
                <div className="outcome-left">
                  <p>
                    To provide the highest quality of services, the terme
                    leverages on the capabilities of IoT (cloud & big data)
                    design. The new terme App connects to wearable devices of
                    the athletes to provide accurate & real-time data which is
                    used to provide personalised value to the athlete.
                  </p>
                  <p>
                    Personalised goals are set by doctors/trainers of the terme
                    to offer a holistic plan; as these goals are not only
                    related to training/recovery activities inside the terme but
                    also activities outside the terme in the levico region such
                    as relaxing at a lake, eating at a restaurant or hiking.
                    This was made possible by the forming of strategic 3rd party
                    partnerships with different activities in the region, such
                    as local restaurants,
                  </p>
                </div>
                <div className="outcome-right">
                  <p>
                    arts & cultural events, museums, cycling shops, outdoor &
                    sporting activities such as rowing shops. The athletes can
                    use the new Terme App, which uses gamification techniques,
                    to motivate and push themselves to get points by completing
                    these goals while at the same time taking advantage of what
                    the region has to offer.
                  </p>
                  <p>
                    Professional sports teams can also choose to come to the
                    Terme for their preparations as a complete end-to-end
                    management of the team is offered. This is done by acting as
                    a liaison between the team and the 3rd parties services
                    which are arranged according to the team’s request.
                  </p>
                </div>
              </div>
              <h3>
                Overall we designed an end-to-end & holistic service offering
                for professional athletes & sports teams to improve their
                performance and accelerate their recovery, naturally, thanks to
                therapeutic qualities of the thermal water found in the region.
              </h3>
            </div>
            <div className="image">
              <Img fluid={props.data.levicooutcome37.childImageSharp.fluid} />
            </div>
          </section>

          <section id="footertopcta" className="inner">
            <p className="text arrow-back">
              head back to
              <br />
              <Link to="/work">all my work.</Link>
            </p>
          </section>
        </div>
      </div>
    </div>
    <div className="footer-top">
      <div className="inner">
        <h2>other projects.</h2>
        <div id="work">
          <div className="card-wrapper">
            <a href="/work/service-design-kin">
              <div className="image">
                <Img fluid={props.data.project1.childImageSharp.fluid} alt="" />
              </div>
              <div className="card-info">
                <div className="title">kin.</div>
                <div className="text">
                  Conversational-based service for Type II Diabetes management
                  that leverages on trusted relationships.
                </div>
                <div className="tags">
                  <ul>
                    <li>SERVICE DESIGN</li>
                    <li>UX STRATEGY</li>
                    <li>USER EXPERIENCE</li>
                  </ul>
                </div>
              </div>
            </a>
          </div>
          <div className="card-wrapper">
            <a href="/work/service-design-tech-no-mad">
              <div className="image">
                <Img fluid={props.data.project2.childImageSharp.fluid} alt="" />
              </div>
              <div className="card-info">
                <div className="title">tech.no.mad.</div>
                <div className="text">
                  Installation that allows visitors to explore how comfortable
                  they feel in sharing personal data in different situations.
                </div>
                <div className="tags">
                  <ul>
                    <li>RESEARCH</li>
                    <li>USER EXPERIENCE</li>
                    <li>INTERACTION DESIGN</li>
                  </ul>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default ServiceDesignLevicoTerme

export const fluidLevicoImage = graphql`
  fragment fluidLevicoImage on File {
    childImageSharp {
      fluid(maxWidth: 920, quality: 100) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const pageQuery = graphql`
  query {
    levicocover: file(
      relativePath: {
        eq: "projects/service/levico/cover/levico-cover-image.jpg"
      }
    ) {
      ...fluidLevicoImage
    }
    levicochallenge6: file(
      relativePath: { eq: "projects/service/levico/challenge/6.jpg" }
    ) {
      ...fluidLevicoImage
    }
    levicochallenge5: file(
      relativePath: { eq: "projects/service/levico/challenge/5.jpg" }
    ) {
      ...fluidLevicoImage
    }
    levicochallenge4: file(
      relativePath: { eq: "projects/service/levico/challenge/4.jpg" }
    ) {
      ...fluidLevicoImage
    }
    levicochallenge3: file(
      relativePath: { eq: "projects/service/levico/challenge/3.jpg" }
    ) {
      ...fluidLevicoImage
    }
    levicochallenge2: file(
      relativePath: { eq: "projects/service/levico/challenge/2.jpg" }
    ) {
      ...fluidLevicoImage
    }
    levicochallenge1: file(
      relativePath: { eq: "projects/service/levico/challenge/1.jpg" }
    ) {
      ...fluidLevicoImage
    }
    levicoprocess7: file(
      relativePath: { eq: "projects/service/levico/process/7.jpg" }
    ) {
      ...fluidLevicoImage
    }
    levicoprocess8: file(
      relativePath: { eq: "projects/service/levico/process/8.jpg" }
    ) {
      ...fluidLevicoImage
    }
    levicoprocess9: file(
      relativePath: { eq: "projects/service/levico/process/9.jpg" }
    ) {
      ...fluidLevicoImage
    }
    levicoprocess10: file(
      relativePath: { eq: "projects/service/levico/process/10.jpg" }
    ) {
      ...fluidLevicoImage
    }
    levicoprocess11: file(
      relativePath: { eq: "projects/service/levico/process/11.jpg" }
    ) {
      ...fluidLevicoImage
    }
    levicoprocess12: file(
      relativePath: { eq: "projects/service/levico/process/12.jpg" }
    ) {
      ...fluidLevicoImage
    }
    levicoprocess13: file(
      relativePath: { eq: "projects/service/levico/process/13.jpg" }
    ) {
      ...fluidLevicoImage
    }
    levicoprocess14: file(
      relativePath: { eq: "projects/service/levico/process/14.jpg" }
    ) {
      ...fluidLevicoImage
    }
    levicoprocess15: file(
      relativePath: { eq: "projects/service/levico/process/15.jpg" }
    ) {
      ...fluidLevicoImage
    }
    levicoprocess16: file(
      relativePath: { eq: "projects/service/levico/process/16.jpg" }
    ) {
      ...fluidLevicoImage
    }
    levicoprocess17: file(
      relativePath: { eq: "projects/service/levico/process/17.jpg" }
    ) {
      ...fluidLevicoImage
    }
    levicoprocess18: file(
      relativePath: { eq: "projects/service/levico/process/18.jpg" }
    ) {
      ...fluidLevicoImage
    }
    levicoprocess19: file(
      relativePath: { eq: "projects/service/levico/process/19.jpg" }
    ) {
      ...fluidLevicoImage
    }
    levicoprocess20: file(
      relativePath: { eq: "projects/service/levico/process/20.jpg" }
    ) {
      ...fluidLevicoImage
    }
    levicoprocess21: file(
      relativePath: { eq: "projects/service/levico/process/21.jpg" }
    ) {
      ...fluidLevicoImage
    }
    levicoprocess22: file(
      relativePath: { eq: "projects/service/levico/process/22.jpg" }
    ) {
      ...fluidLevicoImage
    }
    levicoprocess23: file(
      relativePath: { eq: "projects/service/levico/process/23.jpg" }
    ) {
      ...fluidLevicoImage
    }
    levicoprocess24: file(
      relativePath: { eq: "projects/service/levico/process/24.jpg" }
    ) {
      ...fluidLevicoImage
    }
    levicoprocess25: file(
      relativePath: { eq: "projects/service/levico/process/25.jpg" }
    ) {
      ...fluidLevicoImage
    }
    levicoprocess26: file(
      relativePath: { eq: "projects/service/levico/process/27.jpg" }
    ) {
      ...fluidLevicoImage
    }
    levicoprocess27: file(
      relativePath: { eq: "projects/service/levico/process/26.jpg" }
    ) {
      ...fluidLevicoImage
    }
    levicoprocess28: file(
      relativePath: { eq: "projects/service/levico/process/28.jpg" }
    ) {
      ...fluidLevicoImage
    }
    levicoprocess29: file(
      relativePath: { eq: "projects/service/levico/process/29.jpg" }
    ) {
      ...fluidLevicoImage
    }
    levicoprocess30: file(
      relativePath: { eq: "projects/service/levico/process/30.jpg" }
    ) {
      ...fluidLevicoImage
    }
    levicoprocess31: file(
      relativePath: { eq: "projects/service/levico/process/31.jpg" }
    ) {
      ...fluidLevicoImage
    }
    levicoprocess32: file(
      relativePath: { eq: "projects/service/levico/process/32.jpg" }
    ) {
      ...fluidLevicoImage
    }
    levicoprocess33: file(
      relativePath: { eq: "projects/service/levico/process/33.jpg" }
    ) {
      ...fluidLevicoImage
    }
    levicoprocess34: file(
      relativePath: { eq: "projects/service/levico/process/34.jpg" }
    ) {
      ...fluidLevicoImage
    }
    levicoprocess35: file(
      relativePath: { eq: "projects/service/levico/process/35.jpg" }
    ) {
      ...fluidLevicoImage
    }
    levicoprocess36: file(
      relativePath: { eq: "projects/service/levico/process/36.jpg" }
    ) {
      ...fluidLevicoImage
    }
    levicooutcome37: file(
      relativePath: { eq: "projects/service/levico/outcome/37.jpg" }
    ) {
      ...fluidLevicoImage
    }
    levico_process: file(
      relativePath: { eq: "projects/process/levico-process.png" }
    ) {
      ...fluidLevicoImage
    }
    project1: file(relativePath: { eq: "projects/6.png" }) {
      ...fluidLevicoImage
    }
    project2: file(relativePath: { eq: "projects/1.png" }) {
      ...fluidLevicoImage
    }
  }
`
